import React from "react"
import { Layout } from '../layout'
import { Helmet } from 'react-helmet'
export default function MenuTemplate({ pageContext: { menu: { title, description, body, images } } }) {
  return <Layout>
    <Helmet>
      <title>{title} Menu – Eric's Italian Bistro – Modern & Italian Cuisine</title>
      {description && <meta name="description" content={description} />}
    </Helmet>
    <div className="menu">
      <h1>{title}</h1>
      {body && <h3 dangerouslySetInnerHTML={{ __html: body }} />}
      {images.map(({ src, alt }, index) => <img key={`menu-image-${index}`} className="menu__image" src={src} alt={alt} />)}
      <br />
      <p>A 3.5% admin fee is added to credit card transactions.</p>
    </div>
  </Layout>
}
